import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// Components
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Pricing from './Pages/Pricing';
// import Resposiveness from './Pages/Resposiveness';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* <Route path="/responsiveness" element={<Resposiveness />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
