import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import assets from "../Assets/assets";

import { MdOutlineMarkEmailRead } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineHomeWork } from "react-icons/md";
import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="bg-gray-100">
      <div className="sticky top-0 z-40">
        <Navbar />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-6 md:mx-[8%]">
        <div className="bg-white p-4 md:p-6 border rounded-xl space-y-4">
          <div>
            <h1 className="font-bold text-green1">Contact Us</h1>
            <h2 className="text-[6vw] md:text-[2.5vw] font-medium">Let us handle your request</h2>
            <p className="text-[3vw] md:text-[0.95vw]">If you have any questions or concerns, Reach out to us now</p>
          </div>
          <div>
            <form action="submit" className="grid space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                  <label htmlFor="firstname">First Name</label>
                  <input type="text" id="firstname" placeholder="First name" className="px-2 py-3 rounded-md border focus:border-primary outline-none w-full md:text-[1vw]" />
                </div>
                <div>
                  <label htmlFor="lastname">Last Name</label>
                  <input type="text" id="lastname" placeholder="Last name" className="px-2 py-3 rounded-md border focus:border-primary outline-none w-full md:text-[1vw]" />
                </div>
              </div>
              <div>
                <label htmlFor="email">Email address</label>
                <input type="text" id="email" placeholder="Email address" className="px-2 py-3 rounded-md border focus:border-primary outline-none w-full md:text-[1vw]" />
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" placeholder="Leave us a message" className="p-2 h-[200px] rounded-md border focus:border-primary outline-none w-full md:text-[1vw]"></textarea>
              </div>

              <div className="flex items-center gap-2">
                <input type="checkbox" name="check" id="check" className="w-4 h-4 accent-primary" />
                <span>I agree to our friendly policy</span>
              </div>

              <button disabled className="bg-green1 rounded-md py-2 text-white">Send message</button>
            </form>
          </div>
        </div>

        <div className="space-y-4">
          <div className="overflow-hidden bg-white rounded-xl ">
            <img src={assets.office2} alt="contactImg" className="object-cover h-[450px]" />
          </div>
          <div className="bg-white rounded-xl space-y-4 p-4">
            <div className="flex gap-2 items-center">
              <MdOutlineMarkEmailRead className="text-3xl rounded-full p-[6px] bg-[#d1e4ff] text-primary" />
              <div>
                <p className="font-semibold text-[4vw] md:text-[1.2vw]">Email</p>
                <p className="text-[3vw] md:text-[0.9vw]">admin@cedarblueaccountants.com</p>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <FaPhoneAlt className="text-3xl rounded-full p-[6px] bg-[#d1e4ff] text-primary" />
              <div>
                <p className="font-semibold text-[4vw] md:text-[1.2vw]">Phone</p>
                <p className="text-[3vw] md:text-[0.9vw]">+44 753 496 0507</p>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <MdOutlineHomeWork className="text-3xl rounded-full p-[6px] bg-[#d1e4ff] text-primary" />
              <div>
                <p className="font-semibold text-[4vw] md:text-[1.2vw]">Address</p>
                <p className="text-[3vw] md:text-[0.9vw]">32, Sussex Road, Dartford, Kent DA1 1SH</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Contact