import { IoIosCheckmarkCircle } from "react-icons/io";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useEffect } from "react";

const PlanCard = ({ price, title, billingInfo, features, color }) => (
  <div className="w-full max-w-md hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer bg-white shadow-lg border border-gray-200 rounded-lg p-6">
    <div className={`flex flex-col items-center text-center py-4 px-2 ${color ? color : "bg-black"} text-white`}>
      <p className="text-[4vw] md:text-[1.2vw] font-bold">{price}</p>
      <p className="text-[6vw] md:text-[1.6vw] font-semibold ">{title}</p>
      <p className="">{billingInfo}</p>
    </div>
    <div className="mt-6 space-y-3 text-gray-700 text-sm md:text-base">
      {features.map((feature, index) => (
        <div key={index} className="flex space-x-3 items-center">
          <IoIosCheckmarkCircle className="text-green1 text-2xl" />
          <p className="text-[3vw] md:text-[1vw] w-[90%]">{feature}</p>
        </div>
      ))}
    </div>
    <a href='https://forms.office.com/r/J5EHzHB7W1' target='_blank' rel='noreferrer'>
      <button className={`w-full mt-8 py-2 ${color ? color : "bg-black"} text-white font-semibold rounded-md hover:bg-gray-800 transition-colors`}>
        Get Started
      </button>
    </a>
  </div>
);

const Pricing = () => {
  const plans = [
    {
      price: "From £150/Year",
      title: "Individual Plan",
      billingInfo: "Billed annually.",
      features: [
        "Complete Bookkeeping service. Processing all sales invoices, receipts, bank statements",
        "Annual Business Accounts",
        "Self-Assessment filing with HMRC",
        "Year End Corporation Tax Computation",
        "Regular reminders of all your deadlines"
      ],
      color: "bg-primary"
    },
    {
      price: "From £250/Year",
      title: "Self-employed Plan",
      billingInfo: "Billed annually.",
      features: [
        "Complete Bookkeeping service. Processing all sales invoices, receipts, bank statements",
        "Annual Business Accounts",
        "Self-Assessment filing with HMRC",
        "Preparation of Company Year-End Financial Statements",
        "Year End Corporation Tax Computation",
        "Regular reminders of all your deadlines",
      ],
      color: "bg-green2"
    },
    {
      price: "From £60/Month",
      title: "Contractor Plan",
      billingInfo: "Billed annually.",
      features: [
        "Complete Bookkeeping service. Processing all sales invoices, receipts, bank statements",
        "Annual Business Accounts",
        "Self-Assessment filing with HMRC",
        "Preparation of Year-End Financial Statements",
        "Submission of Accounts to Companies House",
        "Year End Corporation Tax Computation",
        "Submission of CT600 to HMRC",
        "Statement of Account submission to Companies House",
        "Regular reminders of all your deadlines"
      ],
      color: "bg-tertiary"
    },
    {
      price: "From £500/Year",
      title: "Business Plan",
      billingInfo: "Billed annually.",
      features: [
        "Complete Bookkeeping service. Processing all sales invoices, receipts, bank statements",
        "Annual Business Accounts",
        "Self-Assessment filing with HMRC",
        "Unlimited Accountancy Support",
        "Preparation of Company Year-End Financial Statements",
        "Submission of Accounts to Companies House",
        "Year End Corporation Tax Computation",
        "Submission of CT600 to HMRC",
        "Statement of Account submission to Companies House",
        "Regular reminders of all your deadlines",
        "Business and Tax Advisory included"
      ],
      color: "bg-green1"
    },
    {
      price: "From £500/Year",
      title: "Add-On Services",
      billingInfo: "Billed annually.",
      features: [
        "Bookkeeping & Financial Reporting",
        "Annual Accounts Preparation & Submission",
        "Year End Corporation Tax (CT600) Filing with HMRC",
        "Financial Planning & Business Tax Advisory",
        "Personal Tax Planning",
        "Self-Assessment Tax Return",
        "Financial Planning & Advisory",
        "Business Formation & Registration",
        "Confirmation Statement Filing",
        "Company updates with Companies House",
        "Shareholder Agreements & Restructuring",
        "Business Closure & Dissolution",
        "AML Checks",
        "Compliance & Regulatory Support",
        "Business Advisory",
        "Business Plan Writing",
        "Company Closure",
        "Accounts Payables & Receivables Handling",
        "Accounts Review & Amended Submission",
        "ATED reporting",
        "Mortgage & Loan Advisory",
        "HMRC Dispute Resolution",
        "Investment Appraisal",
        "IR35 Review & Assessment",
        "Management Accounts & Budgeting",
        "PAYE Registration & Payroll RTI Filings",
        "VAT Registration & Return Filings"
      ],
      color: "bg-secondary"
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="sticky top-0 z-40">
        <Navbar />
      </div>

      <div className="bg-gray-50 py-16 lg:px-6 z-30">
        <div id="Plan" className="text-center px-2">
          <p className="inline-block bg-primary text-white text-sm px-6 py-1 rounded-full">
            Pricing plans
          </p>
          <h2 className="text-black text-2xl md:text-4xl font-bold mt-4">
            Choose the Plan that is Right for You
          </h2>
          <p className="text-gray-600 mt-2 text-sm md:text-lg">
            Simple, transparent pricing that grows with you. Try any plan free for 30 days.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12 px-4 lg:px-12">
          {plans.map((plan, index) => (
            <PlanCard key={index} {...plan} />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Pricing;
