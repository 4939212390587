import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-[#04306f] text-white">
            <div className="container mx-auto px-6 py-10 grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* About Section */}
                <div>
                    <h2 className="text-lg font-bold mb-4">About Us</h2>
                    <p className="text-sm text-gray-300">
                        We are a leading accounting service provider dedicated to helping businesses manage their finances effectively. From bookkeeping to tax consulting, we’ve got you covered.
                    </p>
                </div>

                {/* Quick Links */}
                <div>
                    <h2 className="text-lg font-bold mb-4">Quick Links</h2>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" className="text-gray-300 hover:text-[#60aa45]">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/pricing" className="text-gray-300 hover:text-[#60aa45]">
                                Pricing
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" className="text-gray-300 hover:text-[#60aa45]">
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" className="text-gray-300 hover:text-[#60aa45]">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Contact Info */}
                <div>
                    <h2 className="text-lg font-bold mb-4">Contact Us</h2>
                    <ul className="space-y-2 text-sm text-gray-300">
                        <li>
                            <span className="font-bold text-white">Email:</span> admin@cedarblueaccountants.com
                        </li>
                        <li>
                            <span className="font-bold text-white">Phone:</span> +44 753 496 0507
                        </li>
                        <li>
                            <span className="font-bold text-white">Address:</span> 32, Sussex Road, Dartford, Kent DA1 1SH
                        </li>
                    </ul>
                </div>
            </div>

            {/* Bottom Footer */}
            <div className="bg-[#0b4897] py-4 px-6">
                <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-sm text-gray-300">
                    <p>&copy; {new Date().getFullYear()} Accounting Service. All rights reserved.</p>
                    <ul className="flex space-x-4 mt-4 md:mt-0">
                        <li>
                            <Link
                                to="#"
                                className="hover:text-[#64b248] transition-colors"
                                aria-label="Facebook"
                            >
                                Facebook
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="#"
                                className="hover:text-[#64b248] transition-colors"
                                aria-label="Twitter"
                            >
                                Twitter
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="#"
                                className="hover:text-[#64b248] transition-colors"
                                aria-label="LinkedIn"
                            >
                                LinkedIn
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;