import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useEffect, useState } from "react";
import assets from "../Assets/assets";

import { IoClose } from "react-icons/io5";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const teams = [
    {
      position: 'Founder & CEO', name: 'Olaniyi Soaga',
      description: [
        `Olaniyi Soaga is an accomplished ACCA accounting and finance professional with over 15 years of expertise in financial control, liquidity management, and regulatory compliance.`,
        `With extensive experience in financial reporting, budgeting, cash flow management, and internal controls, Olaniyi has worked across major banks to optimize liquidity, maintain compliance, and enhance financial efficiency. His strategic approach to accounting and financial oversight supports sustainable growth and operational resilience.`,
        `Committed to excellence and continuous improvement, he leverages his expertise to drive sound financial decision-making, strengthen financial controls, and align financial strategies with business objectives.`
      ], image: assets.ceo
    },
    {
      position: 'Client Engagement Manager', name: 'Precious Etim', description: [
        `Precious Etim is a dedicated Client Engagement Manager with a strong people-centric approach. She bridges the gap between clients and seamless financial solutions, ensuring efficiency, clarity, and trust in every interaction. Passionate about problem-solving and relationship management, Precious leverages her expertise to enhance client experiences, streamline communication, and align engagement strategies with Cedar Blue Accountants' commitment to excellence and long-term success.`
      ], image: assets.etim
    },
  ]

  const [showMore, setShowMore] = useState(
    teams.map(() => false) // Initialize an array of false values
  );
  // const [showMoreModal, setShowMoreModal] = useState(false);

  const handleToggle = (index) => {
    const updatedShowMore = [...showMore];
    updatedShowMore[index] = !updatedShowMore[index];
    setShowMore(updatedShowMore);
  };

  const truncatedText = teams.map(text => text.description).slice(0, 10)
  console.log(truncatedText)

  return (
    <div>
      <div className="sticky top-0 z-40">
        <Navbar />
      </div>

      <div className="bg-gradient-to-b from-blue-50 to-white py-16 px-6 lg:px-20">
        <div className="max-w-6xl mx-auto">
          {/* Header */}
          <h1 className="text-4xl font-bold text-center text-blue-800 mb-12">About Us</h1>

          {/* Mission Section */}
          <section className="mb-16">
            <h2 className="text-3xl font-semibold text-primary mb-4">Our Mission</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              At Cedar Blue Accountants, we provide expert accounting, tax, and advisory services across South East England. With a client-focused approach, we deliver tailored financial solutions that drive growth, efficiency, and long-term success. Rooted in trust, integrity, and innovation, we simplify financial complexities—helping businesses and individuals thrive with confidence.

            </p>
            <p className="text-lg text-gray-600 leading-relaxed mt-4 font-semibold">
              Your finances. Your future. Our expertise.
            </p>
          </section>

          {/* What We Do Section */}
          <section className="mb-16">
            <h2 className="text-3xl font-semibold text-primary mb-4">What We Do</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              At Cedar Blue Accountants, we provide comprehensive accounting, tax, and financial advisory services tailored to businesses and individuals. Our expertise covers everything from bookkeeping and payroll to tax planning, compliance, and strategic financial consulting.
              We help businesses streamline their finances, maximize tax efficiency, and achieve long-term growth. Whether you need day-to-day accounting support or high-level financial strategy, we offer reliable, transparent, and forward-thinking solutions to meet your needs.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed mt-4 font-semibold">
              Expert financial guidance — so you can focus on what matters most.
            </p>
          </section>

          {/* Team Section */}
          <section className="mb-16">
            <h2 className="text-3xl font-semibold text-primary mb-8 text-center">Meet Our Team</h2>
            <div className="flex flex-col md:flex-row gap-8 justify-center">
              {/* Owner Card */}
              {teams.map((team, index) => (
                <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full md:w-[400px]">
                  <img
                    src={team.image}
                    alt={team.position}
                    className="w-full h-[350px] object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-bold text-primary mb-2">{team.name}</h3>
                    <p className="text-sm text-gray-600">{team.position}</p>
                    <p className="mt-4 text-gray-600">{team.description[0].slice(0, 78)}</p>
                    <button
                      className="mt-2 text-primary font-medium"
                      onClick={() => handleToggle(index)}
                    >
                      {showMore[index] ? "Show Less" : "Show More"}
                    </button>
                  </div>

                  {showMore[index] && (
                    <div className="fixed top-0 lg:px-40 left-0 w-full h-[100dvh] overflow-scroll bg-[#fff] flex items-center justify center z-50">
                      <div className="bg-white h-[100dvh] lg:h-[80dvh] p-4 lg:p-10 space-y-4 relative">
                        <IoClose className="absolute right-10 text-xl cursor-pointer" onClick={() => handleToggle(index)} />
                        <div className="flex items-end gap-4">
                          <img src={team.image} alt="" className="w-40 h-40 rounded-full object-cover" />
                          <div >
                            <h3 className="text-2xl font-bold text-primary mb-2">{team.name}</h3>
                            <p>{team.position}</p>
                          </div>
                        </div>
                        <p className="bg-white ">
                          {team.description.map((para, i) => (
                            <p key={i} className="mb-4">{para}</p>
                          ))}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

              ))}

            </div>
          </section>

          {/* Highlights Section */}
          <section className="bg-blue-100 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4">Why Choose Us</h2>
            <ul className="space-y-3">
              <li className="text-lg text-gray-700 flex items-start">
                <span className="w-2 h-2 bg-blue-700 rounded-full mt-2 mr-3"></span>
                Tailored services for each client to meet unique needs effectively.
              </li>
              <li className="text-lg text-gray-700 flex items-start">
                <span className="w-2 h-2 bg-blue-700 rounded-full mt-2 mr-3"></span>
                Support for businesses of all sizes, from small to large enterprises.
              </li>
              <li className="text-lg text-gray-700 flex items-start">
                <span className="w-2 h-2 bg-blue-700 rounded-full mt-2 mr-3"></span>
                A strong track record of success with over 15 years of experience.
              </li>
            </ul>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About;