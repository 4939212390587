import logo from './logo.png';
import contactBg from './contactBg.jpeg';
import heroImg from './heroImg.png';
import man1 from './man1.avif';
import woman1 from './woman1.avif';
import woman2 from './woman2.webp';
import tax from './tax.jpg';
import ceo from './ceo.jpg';
import accountant from './accountant.avif';
import etim from './etim.jpg';
import office from './office.jpg'
import office2 from './office2.jpg'
import finance from './finance.jpg'
import hero3 from './hero3.jpg'
import cedar3d from './cedar3d.png'
import felLogo from './FELlogo.png'
import PenielHomesLogo from './PenielHomesLogo.jpg'
import PWCorpLogo from './PWCorpLogo.png'
import SmartChoiceLogo from './SmartChoiceLogo.png'
import SwavEnt from './SwavEnt.png'
import SwavHairLogo from './SwavHairLogo.png'
import WSPE_LOGO from './WSPE_LOGO.png'
import WynnLogo from './WynnLogo.JPG'

const assets = {
    logo,
    contactBg,
    heroImg,
    man1,
    woman1,
    woman2,
    tax,
    ceo,
    accountant,
    etim,
    office,
    office2,
    finance,
    hero3,
    cedar3d,
    felLogo,
    PenielHomesLogo,
    PWCorpLogo,
    SmartChoiceLogo,
    SwavEnt,
    SwavHairLogo,
    WSPE_LOGO,
    WynnLogo,
}

export default assets;