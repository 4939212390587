import { useEffect } from 'react';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import assets from "../Assets/assets";

// import { MdArrowOutward } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
// import { RiBookkeepingFill } from "react-icons/ri"; 
import { FaFileInvoiceDollar } from "react-icons/fa"; // Tax Planning & Compliance
import { FaMoneyCheckAlt } from "react-icons/fa"; // Payroll Management
// import { FaHandHoldingUsd } from "react-icons/fa"; // Financial Advisory
import TestimonialSection from './Testimonial';
import WhatsApp from '../Components/WhatsApp';

const Home = () => {
  const navigate = useNavigate()
  // const [count, setCount] = useState(0);
  // const [count2, setCount2] = useState(0);
  // const [count3, setCount3] = useState(0);
  // const [isInView, setIsInView] = useState(false);
  // const counterRef = useRef(null);

  // useEffect(() => {
  //   const currentRef = counterRef.current;
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsInView(true);
  //       }
  //     },
  //     { threshold: 0.5 } // Adjust threshold to determine when the element is "in view"
  //   );

  //   if (currentRef) {
  //     observer.observe(currentRef);
  //   }

  //   return () => {
  //     if (currentRef) {
  //       observer.unobserve(currentRef);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isInView && count < 1000) {
  //     const interval = setInterval(() => {
  //       setCount((prev) => {
  //         if (prev < 1000) return prev + 10; // Increment count by 10
  //         clearInterval(interval); // Stop animation when reaching 1000
  //         return prev;
  //       });
  //     }, 10); // Adjust interval speed (in ms) as needed

  //     const interval2 = setInterval(() => {
  //       setCount2((prev) => {
  //         if (prev < 250) return prev + 2.5;
  //         clearInterval(interval2);
  //         return prev;
  //       });
  //     }, 10);

  //     const interval3 = setInterval(() => {
  //       setCount3((prev) => {
  //         if (prev < 500) return prev + 5;
  //         clearInterval(interval3);
  //         return prev;
  //       });
  //     }, 10);

  //     return () => {
  //       clearInterval(interval);
  //       clearInterval(interval2);
  //       clearInterval(interval3);
  //     };
  //   }
  // }, [isInView, count]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const teams = [
    {
      position: 'Managing Partner', name: 'Olaniyi Soaga',
      description: [
        `Certified accounting expert.`
      ], image: assets.ceo
    },
    {
      position: 'Client Engagement Manager', name: 'Precious Etim', description: [
        `Helping businesses optimize tax savings`
      ], image: assets.etim
    },
  ]

  return (
    <div className="">
      <div className="sticky top-0 z-40">
        <Navbar />
      </div>

      <div>
        {/* Hero */}
        <div className="bg-gradient-to-tl from-primary to-secondary relative overflow-hidden flex items-center">
      <div className="flex h-screen md:w-1/2">
        <div className="w-full px-6 lg:px-14 py-10 flex flex-col justify-center">
          <h1 className="text-[8.5vw] md:text-4xl lg:text-5xl leading-tight text-white font-medium">
            Smarter Accounting. Stronger Business.
            <span className="block text-[7.5vw] md:text-3xl lg:text-4xl">
              Expert Financial Solutions for Growth & Stability
            </span>
          </h1>
          <p className="text-[4.3vw] md:text-lg lg:text-base mt-6 font-light text-white">
            At Cedar Blue Accountants, we provide accounting, tax, and advisory services to help businesses stay compliant, optimize cash flow, and drive profitability. With 15+ years of experience, we tailor financial strategies that work for you, so you can focus on running your business confidently.
          </p>
          
          <div className="mt-6">
            <a
              href="https://forms.office.com/r/J5EHzHB7W1"
              target="_blank"
              rel="noreferrer"
              className="px-6 py-3 bg-green2 hover:bg-[#88da6a] transition-colors duration-300 text-white rounded-full"
            >
              Schedule a Free Consultation
            </a>
          </div>
          
          <div className="lg:flex justify-end mt-6">
            <div className="lg:flex lg:gap-6 items-center">
              <div className="flex -space-x-2">
                <img src={assets.man1} alt="Client 1" className="w-12 h-12 rounded-full object-cover" />
                <img src={assets.woman1} alt="Client 2" className="w-12 h-12 rounded-full object-cover" />
                <img src={assets.woman2} alt="Client 3" className="w-12 h-12 rounded-full object-cover" />
              </div>
              <div>
                <p className="text-white text-2xl font-medium">63 Clients</p>
                <p className="text-white text-sm md:text-base font-light">Trust us with their businesses</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="hidden md:block md:w-1/2 h-screen">
        <img src={assets.hero3} alt="Hero" className="w-full h-full object-cover" />
      </div>
    </div>

        {/* Why choose us */}
        <section class="bg-gray-100 py-16">
          <div class="container mx-auto px-6 lg:px-40">
            <div class="text-center mb-12">
              <h2 class="text-3xl font-semibold text-gray-800 mb-4">Why Choose Cedar Blue Accountants?</h2>
              <div class="w-20 h-1 bg-blue-800 mx-auto"></div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              <div class="bg-white p-6 shadow rounded hover:bg-blue-200 cursor-default">
                <div class="flex justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800 mb-2 text-center">Smarter Accounting for Stronger Businesses</h3>
                <p class="text-gray-600 text-center">Tailored accounting and financial advisory services to match your business needs.</p>
              </div>

              <div class="bg-white p-6 shadow rounded hover:bg-blue-200 cursor-default">
                <div class="flex justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800 mb-2 text-center">Optimize Cash Flow & Reduce Tax Burdens</h3>
                <p class="text-gray-600 text-center">Proven tax efficiency strategies to keep more of your earnings.</p>
              </div>

              <div class="bg-white p-6 shadow rounded hover:bg-blue-200 cursor-default">
                <div class="flex justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800 mb-2 text-center">15+ Years of Expertise</h3>
                <p class="text-gray-600 text-center">Trusted by businesses across South East England for financial stability and long-term profitability.</p>
              </div>

              <div class="bg-white p-6 shadow rounded hover:bg-blue-200 cursor-default">
                <div class="flex justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800 mb-2 text-center">Compliance & Growth Advisory</h3>
                <p class="text-gray-600 text-center">We ensure your finances are in order while helping you scale with confidence.</p>
              </div>
            </div>

            <div class="text-center mt-12">
              <p class="text-xl text-gray-700 italic mb-6">Let’s build a stronger financial future together.</p>
              <a href='https://forms.office.com/r/J5EHzHB7W1' target='_blank' rel='noreferrer' class="inline-block bg-primary hover:bg-secondary text-white font-medium py-2 px-6 rounded transition duration-300 border">
                Get Started Today
              </a>
            </div>
          </div>
        </section>

        {/* Our services */}
        <div className="bg-secondary rounded-xl p-6 lg:p-16 my-10 lg:mx-[5%] space-y-10">
          <div>
            <p className="text-green1">Our services</p>
            <h4 className="text-[6.5vw] text-white lg:text-[2.5vw] lg:w-[40%]">Experience that grows with your scale</h4>
          </div>
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-6">
            <div className="space-y-4 bg-primary p-6">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center">
                <FaMoneyCheckAlt className="text-4xl text-green1 " />

              </div>
              <div>
                <h4 className="font-medium text-xl text-white">Accounting & Bookkeeping</h4>
                <p className="text-gray-400">Stay compliant and financially healthy with organized records and expert financial management.</p>
              </div>
            </div>

            <div className="space-y-4 bg-primary p-6">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center">
                <FaFileInvoiceDollar className="text-4xl text-green1" />
              </div>
              <div>
                <h4 className="font-medium text-xl text-white">Tax Planning & Compliance</h4>
                <p className="text-gray-400">Minimize tax burdens while ensuring your business meets all regulatory requirements.</p>
              </div>
            </div>

            <div className="space-y-4 bg-primary p-6">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center">
                <FaMoneyBillTrendUp className="text-4xl text-green1" />
              </div>
              <div>
                <h4 className="font-medium text-xl text-white">Payroll Management</h4>
                <p className="text-gray-400">Simplify payroll with accurate and timely processing for businesses of all sizes.</p>
              </div>
            </div>
            {/* <div className="space-y-4 bg-primary p-6">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center">
                <FaHandHoldingUsd className="text-4xl text-green1" />
              </div>
              <div>
                <h4 className="font-medium text-xl text-white">Financial Advisory</h4>
                <p className="text-gray-400">Get strategic financial insights that drive long-term profitability and growth.</p>
              </div>
            </div>  */}
          </div>

          <div class="text-center mt-12">
            <p class="text-xl text-white italic mb-6">Expert solutions for a financially secure future.</p>
            {/* <Link to="/contact" class="inline-block bg-primary hover:bg-secondary text-white font-medium py-2 px-6 rounded transition duration-300 border">
              Speak to an Expert
            </Link> */}
            <WhatsApp />
          </div>
        </div>

        {/* Pricing */}
        <section className="bg-gray-50 my-16 lg:px-10">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              {/* Text and CTA Section */}
              <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0">
                <h2 className="text-4xl font-bold text-gray-800 mb-4">
                  Flexible & Transparent Pricing
                </h2>
                <p className="text-lg text-gray-600 mb-8">
                  We believe in cost-effective accounting solutions that support businesses of all sizes. Whether you’re a startup, small business, or established enterprise, our services are designed to provide financial stability and growth without unnecessary strain.
                </p>
                <button className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-300" onClick={() => navigate('/pricing')}>
                  View Pricing
                </button>
              </div>

              {/* Image Section */}
              <div className="md:w-1/2 flex justify-center md:justify-end">
                <img
                  src={assets.finance} // Replace with your image URL
                  alt="Pricing Illustration"
                  className="object-cover"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section className="py-10 flex flex-col items-center">
          <h2 className="text-3xl font-semibold text-primary mb-8 text-center">Meet Our Experts</h2>
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            {/* Owner Card */}
            {teams.map((team, index) => (
              <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full md:w-[400px]">
                <img
                  src={team.image}
                  alt={team.position}
                  className="w-full h-[350px] object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-primary mb-2">{team.name}</h3>
                  <p className="text-sm text-gray-600">{team.position}</p>
                  <p className="mt-4 text-gray-600">{team.description[0].slice(0, 78)}</p>
                </div>
              </div>
            ))}

          </div>
          <Link to={'/about'} className='mt-10 rounded-lg bg-primary text-white py-2 px-6 hover:bg-secondary'>Know more</Link>
        </section>

        <TestimonialSection />

        {/* Pricing */}
        <section class="py-16 bg-gradient-to-br from-blue-50 to-gray-50">
          <div class="container mx-auto px-4">
            <div class="relative">
              <div class="absolute -top-12 left-1/4 w-20 h-20 bg-blue-300 rounded-full opacity-50"></div>
              <div class="absolute -bottom-8 right-1/4 w-32 h-32 bg-blue-200 rounded-full opacity-30"></div>
            </div>

            <div class="max-w-3xl mx-auto text-center relative z-10">
              <div class="inline-block bg-white p-4 rounded-full shadow-lg mb-8">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>

              <h2 class="text-4xl  font-bold text-gray-800 mb-6">
                <span class="relative">
                  Affordable & Transparent Pricing
                </span>
              </h2>

              <div class="bg-white p-8 rounded-lg shadow-md mb-10 transform hover:scale-105 transition-transform duration-300">
                <p class="text-gray-700 text-lg leading-relaxed">
                  We offer flexible pricing plans designed to fit businesses of all sizes. Whether you're a startup, small business, or large enterprise, our cost-effective accounting solutions help you grow without financial strain.
                </p>
              </div>

              <div class="bg-blue-800 rounded-lg p-8 shadow-xl transform -rotate-1 hover:rotate-0 transition-transform duration-300">
                <h3 class="text-2xl  font-bold text-white mb-4">Request a Quote Today!</h3>
                <Link to="/pricing" class="inline-block bg-white text-blue-800 font-bold py-4 px-10 rounded-full shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300">
                  <span class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                    View Pricing
                  </span>
                </Link>
              </div>

              <div class="mt-12 flex flex-wrap justify-center items-center gap-6">
                <div class="flex items-center text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>No Hidden Fees</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Flexible Plans</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Monthly Billing</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact */}
        <section class="py-16 bg-gray-50">
          <div class="container mx-auto px-4">
            <div class="max-w-5xl mx-auto">
              <div class="text-center mb-12">
                <h2 class="text-3xl  font-semibold text-gray-800 mb-4">Contact Us</h2>
                <div class="w-20 h-1 bg-blue-800 mx-auto"></div>
              </div>

              <div class="bg-white rounded-lg shadow-lg overflow-hidden">
                <div class="flex flex-col md:flex-row">
                  <div
                    className="w-full md:w-1/2 relative bg-blue-800"
                    style={{
                      backgroundImage: `url(${assets.cedar3d})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                  >
                    {/* Dark overlay div */}
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                    <div className="h-full flex items-center justify-center p-8 relative z-10">
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-24 w-24 text-white mx-auto mb-6 opacity-80"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <h3 className="text-2xl font-semibold text-white mb-4">Visit Our Office</h3>
                        <p className="text-blue-100">We'd love to meet you in person and discuss your financial needs</p>
                      </div>
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 p-4 md:p-8 ">
                    <div class="space-y-8">
                      <div class="space-y-4">
                        <div class="flex items-start">
                          <div class="flex-shrink-0 bg-blue-100 rounded-full p-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                          </div>
                          <div class="ml-2 md:ml-4">
                            <h4 class="text-lg font-semibold text-gray-800">Address</h4>
                            <p class="text-gray-600">32, Sussex Road, Dartford, Kent DA1 1SH</p>
                          </div>
                        </div>

                        <div class="flex items-start">
                          <div class="flex-shrink-0 bg-blue-100 rounded-full p-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                          </div>
                          <div class="ml-2 md:ml-4">
                            <h4 class="text-lg font-semibold text-gray-800">Email</h4>
                            <p class="text-gray-600 text-[15px] md:text-md ">admin@cedarblueaccountants.com</p>
                          </div>
                        </div>

                        <div class="flex items-start">
                          <div class="flex-shrink-0 bg-blue-100 rounded-full p-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                          </div>
                          <div class="ml-2 md:ml-4">
                            <h4 class="text-lg font-semibold text-gray-800">Phone</h4>
                            <p class="text-gray-600">+44 753 496 0507</p>
                          </div>
                        </div>
                      </div>

                      <div class="border-t pt-6">
                        <p class="text-gray-700 font-medium mb-4 text-center">Let's discuss how we can help your business thrive.</p>
                        <a href='https://forms.office.com/r/J5EHzHB7W1' rel='noreferrer' target='_blank' class="flex items-center justify-center bg-blue-800 hover:bg-blue-900 text-white font-medium py-3 px-6 rounded-lg transition duration-300 w-full">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                          </svg>
                          Book a Free Consultation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="mt-10 text-center">
                <h3 class="text-xl  font-semibold text-gray-800 mb-2">Business Hours</h3>
                <p class="text-gray-600">Monday - Friday: 9:00 AM - 5:00 PM</p>
                <p class="text-gray-600">Saturday & Sunday: Closed</p>
              </div> */}
            </div>
          </div>
        </section>

        {/* <div className="px-6 py-14 lg:p-20 bg-primary space-y-10">
          <div>
            <p className="text-green1">STEPS TO GET STARTED</p>
            <h4 className="text-[6.5vw] lg:text-[2.5vw] lg:w-[40%] text-white">Maximize your returns with our tailored solutions</h4>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-10">
            <div className="rounded-lg p-10 bg-secondary md:w-[33%]">
              <h1 className="text-6xl font-bold bg-gradient-to-t from-primary via-[#507cb5] to-white text-transparent bg-clip-text">1</h1>
              <h4 className="text-[20px] text-white font-semibold">Sign up</h4>
              <p className="text-gray-300 text-[1.7vh] md:text-[1vw]">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore id quisquam officia voluptatibus dolorem?</p>
            </div>
            <div className="rounded-lg p-10 bg-secondary md:w-[33%]">
              <h1 className="text-6xl font-bold bg-gradient-to-t from-primary via-[#507cb5] to-white text-transparent bg-clip-text">2</h1>
              <h4 className="text-[20px] text-white font-semibold">Select a plan</h4>
              <p className="text-gray-300 text-[1.7vh] md:text-[1vw]">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi eius quia fugit voluptas.</p>
            </div>
            <div className="rounded-lg p-10 bg-secondary md:w-[33%]">
              <h1 className="text-6xl font-bold bg-gradient-to-t from-primary via-[#507cb5] to-white text-transparent bg-clip-text">3</h1>
              <h4 className="text-[17px] text-white font-semibold">Purchase plan</h4>
              <p className="text-gray-300 text-[1.7vh] md:text-[1vw]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto cumque </p>
            </div>
          </div>
        </div> */}

        {/* Counter */}
        {/* <div
          className="lg:h-screen py-20 lg:py-0 flex flex-col gap-10 items-center justify-center bg-white"
          ref={counterRef}
        >
          <div className='flex justify-center flex-col items-center gap-2 text-center'>
            <h1 className="text-[4vh] lg:text-[3vw] font-medium lg:w-[60%]">We've helped inovative companies</h1>
            <p className='lg:w-[50%]'>Hundreds of all sizes and across all industries have made a big improvments with us</p>
          </div>
          <div className='flex flex-col lg:flex-row gap-10 lg:gap-40'>
            <div className='flex flex-col items-center gap-2'>
              <span className="text-5xl font-semibold text-green1">{count2}%</span>
              <p>Revenue business</p>
            </div>
            <div className='flex flex-col items-center gap-2'>
              <span className="text-5xl font-semibold text-green1">+{count}K</span>
              <p>In annual revenue</p>
            </div>
            <div className='flex flex-col items-center gap-2'>
              <span className="text-5xl font-semibold text-green1">+{count3}K</span>
              <p>Companies</p>
            </div>
          </div>
        </div> */}

        {/* Client */}
        <div className="bg-gray-50 py-36 px-8">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold text-primary mb-6">Our Esteemed Clients</h2>
            <p className="text-lg text-gray-400 opacity-90 mb-12">
              Trusted by companies across various industries to deliver excellence.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center">
              <div className="bg-black shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.felLogo} alt="Client 1 Logo" className="w-20 h-20 object-contain" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.PenielHomesLogo} alt="Client 2 Logo" className="w-30 h-20 object-contain" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.PWCorpLogo} alt="Client 3 Logo" className="w-[135px] h-20 object-contain" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.SmartChoiceLogo} alt="Client 4 Logo" className="w-[135px] h-20 object-contain" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.SwavEnt} alt="Client 5 Logo" className="w-[135px] h-20 object-cover" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.SwavHairLogo} alt="Client 6 Logo" className="w-[110px] h-20 object-contain" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.WSPE_LOGO} alt="Client 7 Logo" className="w-[165px] h-20 object-cover" />
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 flex justify-center items-center">
                <img src={assets.WynnLogo} alt="Client 8 Logo" className="w-20 h-20 object-contain" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default Home



// < div className = "bg-gray-50 text-gray-800" >
//   {/* Hero Section */ }
//   < section className = "relative bg-gradient-to-r from-blue-500 to-blue-700 text-white py-20 px-6 md:px-12 lg:px-24 text-center" >
//       <h1 className="text-4xl md:text-6xl font-bold mb-6">Welcome to Cedar Blue Accountants</h1>
//       <p className="text-lg md:text-xl mb-8">
//         Unlocking your business potential with tailored financial solutions.
//       </p>
//       <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-gray-100">
//         Get Started Today
//       </button>
//     </ >

// {/* Our Mission Section */ }
// < section className = "py-16 px-6 md:px-12 lg:px-24 bg-white" >
//       <h2 className="text-3xl font-bold text-center mb-8">Our Mission</h2>
//       <p className="text-lg leading-8 text-center max-w-3xl mx-auto mb-8">
//         We are focused on creating a better, brighter future for our clients, whether you are an individual or a small to large company. Our goal is to create solutions that are unique to your business needs and help you better manage your finances.
//       </p>
//       <div className="flex flex-wrap justify-center gap-8">
//         <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-sm">
//           <h3 className="text-xl font-bold mb-4">Client-Centric</h3>
//           <p>We dedicate ourselves to giving you more time for your critical business matters by creating unique solutions tailored to your needs.</p>
//         </div>
//         <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-sm">
//           <h3 className="text-xl font-bold mb-4">Growth-Oriented</h3>
//           <p>Our services are designed to meet your current needs and prepare you for future success.</p>
//         </div>
//       </div>
//     </ section>

// {/* What We Do Section */ }
// < section className = "py-16 px-6 md:px-12 lg:px-24 bg-gray-50" >
//       <h2 className="text-3xl font-bold text-center mb-8">What We Do</h2>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//         <div className="bg-white p-6 rounded-lg shadow-lg">
//           <h3 className="text-xl font-bold mb-4">Experience</h3>
//           <p>With over 15 years of experience, we help businesses and individuals achieve their long-term financial goals.</p>
//         </div>
//         <div className="bg-white p-6 rounded-lg shadow-lg">
//           <h3 className="text-xl font-bold mb-4">Tailored Services</h3>
//           <p>Our services are personalized to ensure every client’s unique needs are met with precision and care.</p>
//         </div>
//         <div className="bg-white p-6 rounded-lg shadow-lg">
//           <h3 className="text-xl font-bold mb-4">Global Reach</h3>
//           <p>We serve clients both within the United Kingdom and overseas, managing assets worth £1.5 million.</p>
//         </div>
//       </div>
//     </ >

// {/* Call to Action Section */ }
// < section className = "bg-blue-700 text-white py-12 px-6 md:px-12 lg:px-24 text-center" >
//       <h2 className="text-3xl font-bold mb-4">Ready to grow your business?</h2>
//       <p className="text-lg mb-6">Contact Cedar Blue Accountants today and unlock your business’s true potential.</p>
//       <button className="bg-white text-blue-700 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-gray-100">
//         Contact Us
//       </button>
//     </ >
//   </div >